/* ErrorBanner.css */
.error-banner {
  position: absolute;
  bottom: -60px; /* Initial position below the viewport */
  right: 10px;
  width: 300px;
  height: 40px;
  background-color: #ff6347; /* Red background color */
  color: white; /* White text color */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  animation: slideIn 0.5s forwards; /* Animation definition */
}

@keyframes slideIn {
  from {
    bottom: -60px; /* Start from this position below the viewport */
  }

  to {
    bottom: 10px; /* End at this position just above the viewport */
  }
}

.error-message {
  flex: 1;
  padding-right: 10px;
}

.error-banner-container {
  overflow: hidden; /* Prevent content from shifting during animation */
}
