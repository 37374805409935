.Doc-Management-Main {
    /* background-color: grey; */
    display: flex;
    flex-direction: column;
    width: "100%";
    text-align: "center";
    align-items: "center";
    justify-content: "center";
    display: 'block';
    padding-top: '60px';
}
  